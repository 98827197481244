import {Button, ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';


import {Text, View} from '../components/Themed';
import React, {useRef, useState} from "react";
import {useForm} from "../hooks/useForm";
import {styles} from "../constants/Styles";
import Colors from "../constants/Colors";
import {apiURL, trpc} from "../trpc";
import {ChatCompletionResponseMessage} from "openai";
import {faCircleXmark, faChevronDown, faChevronRight} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon,} from '@fortawesome/react-native-fontawesome';
import UiButton from '../components/UiButton';
import {randomId} from "../utils/rando";
import {charI} from '../../../dnd-friend-api/src/dm/types';

export default function DmScreen() {
    const [userId, setUserId] = useState(randomId())
    const [dmResponse, setDmResponse] = useState<ChatCompletionResponseMessage>()
    const [showPresets, setShowPresets] = useState(false)
    const [showParty, setShowParty] = useState(false)
    const [imageUrl, setImageUrl] = useState("")
    const initForm = {
        userMessage: "",
        class: "",
        race: "",
        name: "",
        weapon: "",
        level: "",
        class2: "",
        name2: "",
        weapon2: "",
        race2: "",
        level2: ""
    }
    const form = useForm({
        ...initForm,
        userMessage: "Describe our party",
        /*class: "Fighter",
        race: "Dwarf",
        name: "Bruenar",
        weapon: "Battle Axe",


        class2: "Wizard",
        race2: "Human",
        name2: "Wanderia",
        weapon2: "Spells and Staff",*/

    })
    const input_ref = useRef<TextInput | null>(null);
    const dm = trpc.dm.createDm.mutate
    const dmImage = trpc.dm.createDmImage.mutate
    // end of hooks

    const handleAddWord = (word: string) => {

        form.setFormData({
            ...form.formData,
            userMessage: `${word}`
        })
    }


    const handleSubmit = async () => {
        form.setSubmitting(true)
        setShowParty(false)
        setShowPresets(false)
        console.log("submit name", form.formData.userMessage)
        //chat.mutate({message: form.formData.userMessage});
        let character1: charI = {
            level: form.formData.level,
            race: form.formData.race,
            name: form.formData.name,
            class: form.formData.class,
            weapon: form.formData.weapon,
        }

        let character2: charI = {
            level: form.formData.level2,
            race: form.formData.race2,
            name: form.formData.name2,
            class: form.formData.class2,
            weapon: form.formData.weapon2,
        }

        let characters = [
            character1,
            character2
        ]
        console.log(characters)
        try {
            console.log(apiURL)
            dm({
                message: form.formData.userMessage,
                user: userId,
                characters
            }).then(res => {
                console.log("dm finished", res)
                //let r = parseAndRemoveJSON(res.content)
                //res.content=r.remainingText
                setDmResponse(res)
                //form.setFormData(initForm)
                form.setSubmitting(false)
                //kinda annoying input_ref.current?.focus()
                handleGenerateImage(res.content)
            });
        } catch (err) {
            console.log(err)
        }
    }

    function parseAndRemoveJSON(text: string): { jsonData: any, remainingText: string } {
        // Regular expression to match the JSON part
        const jsonRegex = /```json\s+([\s\S]+?)\s+```/;

        // Find and extract the JSON part
        const jsonMatch = text.match(jsonRegex);
        let jsonData = null;

        if (jsonMatch && jsonMatch[1]) {
            // Parse the JSON string
            try {
                jsonData = JSON.parse(jsonMatch[1]);
            } catch (error) {
                console.error("Error parsing JSON:", error);
                // Handle JSON parsing error
            }
        }

        // Remove the JSON part from the text
        const remainingText = text.replace(jsonRegex, '');

        return { jsonData, remainingText };
    }

    const handleGenerateImage = async (prompt: string) => {
        console.log("generate image", prompt)

        try {
            console.log(apiURL)
            dmImage({message: form.formData.userMessage, user: userId}).then(url => {
                console.log("image finished", url)
                setImageUrl(url)
            });
        } catch (err) {
            console.log(err)
        }
    }

    const presets = [
        "Describe our surroundings",
        "Go looking for evil monsters",
        "Search for magical treasures",
        "List examples of things to do",
        "Explore the area",
        "Follow the path",
        "Describe our party",
    ]

// if (isLoading) return <span>Loading ...</span>;
    return (

        <View style={styles.container}>
            <ImageBackground
                source={require('../assets/images/playing.jpg')}
                resizeMode="cover" style={styles.image}
            >
                <ScrollView
                    keyboardShouldPersistTaps={'handled'}
                    style={{
                        //alignItems: "center",

                        backgroundColor: Colors.dark.backgroundAlpha,
                        width: "100%",

                    }}>
                    <View style={{
                        ...styles.container,
                        //backgroundColor: Colors.dark.backgroundAlpha,

                    }}>
                        <Text style={styles.title}>Let your DnD Friend be the DM.</Text>

                        <View style={{
                            // ...styles.bordered,
                            //flex:1,
                            width: "100%",
                            alignItems: "center",
                            //height: '100%'
                        }}>
                            <View style={{
                                //flex:1
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                //marginTop: 12,

                            }}>
                                <View
                                    style={{
                                        width: "82%",
                                    }}
                                >
                                    <TextInput
                                        ref={input_ref}
                                        autoFocus={true}
                                        style={styles.input}
                                        //placeholder=""
                                        onSubmitEditing={handleSubmit}
                                        value={form.formData.userMessage}
                                        onChangeText={form.handleChange("userMessage")}
                                    />
                                </View>
                                <TouchableHighlight underlayColor="white"
                                                    onPress={e => {
                                                        form.setFormData(initForm)
                                                        input_ref.current?.focus()
                                                    }}
                                >
                                    <View style={{
                                        //...styles.button,
                                        flexDirection: "row",
                                        padding: "4%"
                                    }}>
                                        <FontAwesomeIcon color={Colors.dark.text} icon={faCircleXmark}/>
                                    </View>
                                </TouchableHighlight>
                            </View>
                            <View style={{
                                flexDirection: "row"
                            }}>
                                <TouchableHighlight underlayColor="white"
                                                    onPress={() => {
                                                        setShowParty(!showParty)
                                                    }}>
                                    <View style={{
                                        ...styles.button,
                                        flexDirection: "row",
                                        padding: 12
                                    }}>
                                        <Text style={{
                                            marginRight: 6,
                                        }}>Party</Text>
                                        <FontAwesomeIcon color={Colors.dark.text}
                                                         icon={showParty ? faChevronDown : faChevronRight}/>
                                    </View>
                                </TouchableHighlight>

                                <TouchableHighlight underlayColor="white"
                                                    onPress={() => {
                                                        setShowPresets(!showPresets)
                                                    }}>
                                    <View style={{
                                        ...styles.button,
                                        flexDirection: "row",
                                        padding: 12
                                    }}>
                                        <Text style={{
                                            marginRight: 6,
                                        }}>Examples</Text>
                                        <FontAwesomeIcon color={Colors.dark.text}
                                                         icon={showPresets ? faChevronDown : faChevronRight}/>
                                    </View>
                                </TouchableHighlight>
                            </View>
                            {showParty &&
                                <View
                                    style={{
                                        flex: 1,
                                        //flexDirection: "row",
                                        //justifyContent: "space-around",
                                    }}
                                >
                                    <Text style={{color: Colors.dark.text}}>Character 1</Text>
                                    <View
                                        style={{
                                            //flex:1,
                                        }}
                                    >

                                        <TextInput
                                            //ref={input_ref}
                                            autoFocus={false}
                                            style={styles.input}
                                            placeholder="class. Fighter, Wizard, Cleric, Ranger"
                                            onSubmitEditing={handleSubmit}
                                            value={form.formData.class}
                                            onChangeText={form.handleChange("class")}
                                        />
                                        <TextInput
                                            //ref={input_ref}
                                            autoFocus={false}
                                            style={styles.input}
                                            placeholder="race. Human, Elf, Dwarf"
                                            onSubmitEditing={handleSubmit}
                                            value={form.formData.race}
                                            onChangeText={form.handleChange("race")}
                                        />
                                        <TextInput
                                            //ref={input_ref}
                                            autoFocus={false}
                                            style={styles.input}
                                            placeholder="name"
                                            onSubmitEditing={handleSubmit}
                                            value={form.formData.name}
                                            onChangeText={form.handleChange("name")}
                                        />
                                        <TextInput
                                            //ref={input_ref}
                                            autoFocus={false}
                                            style={styles.input}
                                            placeholder="weapon"
                                            onSubmitEditing={handleSubmit}
                                            value={form.formData.weapon}
                                            onChangeText={form.handleChange("weapon")}
                                        />
                                    </View>
                                    <Text style={{color: Colors.dark.text}}>Character 2</Text>
                                    <View
                                        style={{}}
                                    >
                                        <TextInput
                                            //ref={input_ref}
                                            autoFocus={false}
                                            style={styles.input}
                                            placeholder="class. i.e. Fighter, Wizard, Cleric, Ranger"
                                            onSubmitEditing={handleSubmit}
                                            value={form.formData.class2}
                                            onChangeText={form.handleChange("class2")}
                                        />
                                        <TextInput
                                            //ref={input_ref}
                                            autoFocus={false}
                                            style={styles.input}
                                            placeholder="race. Human, Elf, Dwarf"
                                            onSubmitEditing={handleSubmit}
                                            value={form.formData.race2}
                                            onChangeText={form.handleChange("race2")}
                                        />
                                        <TextInput
                                            //ref={input_ref}
                                            autoFocus={false}
                                            style={styles.input}
                                            placeholder="name"
                                            onSubmitEditing={handleSubmit}
                                            value={form.formData.name2}
                                            onChangeText={form.handleChange("name2")}
                                        />
                                        <TextInput
                                            //ref={input_ref}
                                            autoFocus={false}
                                            style={styles.input}
                                            placeholder="weapon"
                                            onSubmitEditing={handleSubmit}
                                            value={form.formData.weapon2}
                                            onChangeText={form.handleChange("weapon2")}
                                        />
                                    </View>
                                </View>
                            }
                            {showPresets && (
                                <View style={{
                                    marginHorizontal: 16,
                                    flexWrap: "wrap",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    marginBottom: 12,
                                }}>
                                    {presets.map((p, i) => (
                                        <TouchableHighlight key={"preset" + i}
                                                            underlayColor="white"
                                                            onPress={() => handleAddWord(p)}
                                        >
                                            <View style={styles.button}>
                                                <Text>{p}</Text>
                                            </View>
                                        </TouchableHighlight>
                                    ))}
                                </View>
                            )}

                            <UiButton
                                disabled={form.submitting}
                                onPress={handleSubmit}
                            >
                                <Text style={styles.buttonText}>{form.submitting ? "Working on it.." : "DM"}</Text>
                            </UiButton>

                            {dmResponse &&
                                <View style={{
                                    padding: 4,
                                    margin: 12,
                                    marginBottom: "20%",
                                }}>
                                    {/*  <Text style={styles.title}>
                    {chatResponse.role}
                  </Text>*/}
                                    <Text style={styles.card}>
                                        {dmResponse.content}
                                    </Text>
                                </View>}

                            {imageUrl && (
                                <img src={imageUrl}/>
                            )}
                        </View>
                    </View>
                </ScrollView>
            </ImageBackground>
        </View>

    );
}

