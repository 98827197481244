import {StyleSheet} from 'react-native';

import EditScreenInfo from '../components/EditScreenInfo';
import {Text, View} from '../components/Themed';
import {RootTabScreenProps} from '../types';
import {setToken,  trpc} from "../trpc";
import {styles} from "../constants/Styles";
import {useEffect, useState} from "react";
import {User} from '../../../dnd-friend-api/src/user/types';

export default function ProfileScreen({navigation}: RootTabScreenProps<'Profile'>) {
  const [data, setData] = useState<User>()
  //const { data, isLoading } = trpc.user.getUserById.useQuery('1000');

  const fetchUser = async () => {
    setToken("super donkey")
    const user = await trpc.user.getUserById.query('1000');
    setData(user)
    console.log(user);
  };

  useEffect(() => {
    fetchUser();
  }, [trpc]);

  //if (isLoading) return <div>Loading ...</div>;

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Profile</Text>

      <Text>{data?.name}</Text>

    </View>
  );
}

