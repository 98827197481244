import {Dimensions, StyleSheet} from "react-native";
import Colors from "./Colors";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
export const heightCalc = (n: number) => {
  return windowHeight / 1600 * n
}
export const widthCalc = (n: number) => {
  return windowWidth / 1600 * n
}

export const CONSTANTS = {
  borderRadius: 14,
  navBarHeight: 72,
}
export const styles = StyleSheet.create({
  screen: {
    flex: 1,
    maxWidth: 1024,
    flexDirection: "row",
    alignItems: "center",
    justifySelf: "center",
    margin: "auto",
    width: "100%",
    //height: "100%",
  },
  container: {
    flex: 1,
    height: "100%",
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderColor: `rgb(200, 0, 0)`,
    //borderWidth: 5,
  },
  bordered: {
    borderStyle: "dashed",
    borderColor: `rgb(0, 220, 0)`,
    borderWidth: 4,
  },
  title: {
    color: 'rgba(252,254,255,0.92)',
    fontSize: 24,
    fontWeight: "300",
    marginBottom: 24,
    marginTop: 36,
  },
  logo: {
    color: 'rgba(252,254,255,0.92)',
    fontSize: 24,
    fontWeight: "800",
    marginLeft: 24,
    marginRight: 0,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  input: {
    color: Colors.dark.text,
    margin: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderStyle: "solid",
    borderColor: Colors.dark.text,
    borderRadius: CONSTANTS.borderRadius,
    borderWidth: 1,
    backgroundColor: Colors['dark'].background
  },
  button: {
    margin: 2,
    padding: 6,
    backgroundColor: Colors.dark.backgroundAlpha,
    borderRadius: CONSTANTS.borderRadius
  },
  buttonPrimary: {
    margin: 8,
    paddingVertical: 8,
    paddingHorizontal: 14,
    backgroundColor: Colors.dark.tint,
    borderRadius: CONSTANTS.borderRadius,
  },
  buttonText: {
    fontWeight: "900",
  },
  image: {
    flex: 1,
    width: "100%",
    justifyContent: 'center',
  },
  card: {
    fontSize: 16,
    padding: 16,
    margin: 24,
    maxWidth: 600,
    backgroundColor: Colors.dark.background,
    borderWidth: 1,
    borderRadius: CONSTANTS.borderRadius,
    borderColor: Colors['dark'].background
  },
  cardSmall: {
    fontSize: 14,
    padding: 12,
    margin: 4,
    maxWidth: 400,
    backgroundColor: Colors.dark.background,
    //borderWidth: 1,
    borderRadius: CONSTANTS.borderRadius,
    borderColor: Colors['dark'].background
  },
  dice: {
    fontSize: 14,
    padding: 12,
    margin: 4,
    maxWidth: 400,
    backgroundColor: Colors.dark.background,
    //borderWidth: 1,
    borderRadius: CONSTANTS.borderRadius,
    borderColor: Colors['dark'].background,
    justifyContent: "center",
    alignItems: "center",
  },
  diceText: {
    
  }
});
