import React, { useState, useMemo } from "react";
import {
  View,
  Text,
  TouchableHighlight,
  ScrollView,
  Pressable,
} from "react-native";
import { styles } from "../constants/Styles";
import { rollDie } from "../utils/rando";
import Colors from "../constants/Colors";

export interface DiceI {
  sides: number;
}

const Die = ({ sides }: DiceI) => {
  const [rolls, setRolls] = useState<number[]>([]);

  const total = useMemo(() => {
    let t = 0;
    for (let i = 0; i < rolls.length; i++) {
      let roll = rolls[i];
      t = t + roll;
    }

    return t;
  }, [rolls]);
  const handleClickDice = (s: number) => {
    let rs = rolls;
    let roll = rollDie(s);
    let ra = [roll, ...rs];
    // console.log(rs)
    setRolls(ra);
  };
  const handleClearRolls = () => {
    setRolls([])
  }
  // console.log(rolls)

  return (
    <View
      style={{
        width: "100%",
        flex: 1,
      }}
    >
      <TouchableHighlight
        underlayColor="rgba(252,253,255,0.051)"
        //disabled={disabled}
        onPress={(e) => handleClickDice(sides)}
      >
        <View
          style={{
            width: "100%",
            //...styles.bordered,
            flex: 1,
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              flex: 1,
              //marginLeft: 12,
              alignItems: "center",
            }}
          >
            <View
              style={{
                ...styles.cardSmall,
                //width: 150,
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  color: Colors.dark.text,
                  fontSize: 46,
                  fontWeight: "700",
                }}
              >
                {sides}
              </Text>
            </View>

            <View
              style={{
                alignItems: "center",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <ScrollView horizontal={true}>
                {rolls.map((r, i) => (
                  <View
                    key={`die ${sides}::` + "roll::" + sides + "::" + i}
                    style={{
                      ...styles.dice,
                      ...(i === 0 && {
                        background: "rgba(255,255,255,0.7)",
                      }),
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.dark.text,
                        fontSize: 24,
                        ...(i === 0 && {
                          color: "black",
                          fontSize: 46,
                        }),
                        fontWeight: "900",
                      }}
                    >
                      {r}
                    </Text>
                  </View>
                ))}
              </ScrollView>
              <Pressable onPress={() => handleClearRolls()}>
                <View
                  style={{
                    ...styles.dice,
                  }}
                >
                  <Text
                    style={{
                      color: Colors.dark.text,
                      fontSize: 24,
                    }}
                  >
                    {total}
                  </Text>
                </View>
              </Pressable>
            </View>
          </View>
        </View>
      </TouchableHighlight>
    </View>
  );
};

export default Die;
