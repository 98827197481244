import {StatusBar} from 'expo-status-bar';
import {SafeAreaProvider} from 'react-native-safe-area-context';

import useCachedResources from './src/hooks/useCachedResources';
import useColorScheme from './src/hooks/useColorScheme';
import Navigation from './src/navigation';
import {styles} from "./src/constants/Styles";
import React from "react";
import {ImageBackground} from "react-native";


export default function App() {
  const isLoadingComplete = useCachedResources();
//  const colorScheme = useColorScheme();
const colorScheme = "dark"
  if (!isLoadingComplete) {
    return null;
  } else {
    return (
          <SafeAreaProvider>
            <Navigation colorScheme={colorScheme}/>
            {/*<Navigation colorScheme={"dark"} />*/}
            <StatusBar style={"dark"}/>
          </SafeAreaProvider>
    );
  }
}
