import {Button, ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';


import {Text, View} from '../components/Themed';
import React, {useRef, useState} from "react";
import {useForm} from "../hooks/useForm";
import {styles} from "../constants/Styles";
import Colors from "../constants/Colors";
import {apiURL, trpc} from "../trpc";
import {ChatCompletionResponseMessage} from "openai";
import {faCircleXmark, faChevronDown, faChevronRight} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon,} from '@fortawesome/react-native-fontawesome';
import UiButton from '../components/UiButton';
import Die from "../components/Die";
import {rollDie} from "../utils/rando";

export default function DiceScreen() {
  const input_ref = useRef<TextInput | null>(null);
  const createNames = trpc.names.createNames.mutate

  // end of hooks


  const dice = [
    4,
    6,
    8,
    10,
    12,
    20,
    100,
  ]


  return (

    <View style={styles.container}>
      <ImageBackground
        source={require('../assets/images/playing.jpg')}
        resizeMode="cover" style={styles.image}
      >

        <View style={{
          ...styles.container,
          paddingBottom: 72,
        }}>
          <View style={{
            flexDirection: "row",
            justifyContent: "center",
            padding:6,
          }}>

          </View>
          {dice.map((d, i) =>
            <Die sides={d} key={"die::" + i}/>
          )}
        </View>

      </ImageBackground>
    </View>

  );
}

