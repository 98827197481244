import {useState} from "react";

export interface FormOptions {
    label: any;
    value: any;
}


export interface formProps {
    form: {
        formData: any,
        submitting: boolean
        setSubmitting: any
        handleInput: any
        handleSelect: any
        validate: any
    }
    submit?: any
}


export const useForm = (initialData: any) => {
    const [formData, setFormData] = useState({errors: {}, validation: {}, ...initialData});
    let [submitting, setSubmitting] = useState(false);

    const resetFormData = (init?: any) => {
        let validation = formData.validation;
        setFormData({
            validation,
            errors: {},
            ...init
        })
    };
    const validate = () => {
        let result: boolean = true;
        let fd = {...formData};
        Object.entries(formData.validation).forEach(([key, value]) => {

            if (!formData[key]) {
                fd = {
                    ...fd,
                    errors: {
                        ...fd.errors,
                        [key]: "Required Field"
                    }
                };
                result = false;
            }
        });
        setFormData(fd);
        if (!result) {
            setSubmitting(false);
        }
        return result;
    };
    const handleSelect = (name: string, value: any, index?: number) => {
        setSubmitting(false);
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChange = (name:string) => {
        return (value:any) => setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleInput = (e: any) => {
        setSubmitting(false);
        const target = e.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        //const value = target.value;
        const name = target.name;
        //console.log(name, value)
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return {
        formData,
        setFormData,
        handleSelect,
        handleChange,
        handleInput,
        validate,
        resetFormData,
        submitting,
        setSubmitting
    }
};
