import {Button, ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';

import {Text, View} from '../components/Themed';
import React, {useRef, useState} from "react";
import {useForm} from "../hooks/useForm";
import {styles} from "../constants/Styles";
import Colors from "../constants/Colors";
import {trpc} from "../trpc";
import {ChatCompletionResponseMessage} from "openai";
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons"
import {randomId} from "../utils/rando";
import UiButton from "../components/UiButton";

export default function ChatScreen() {
  const [userId, setUserId] = useState(randomId())
  const [chatResponse, setChatResponse] = useState<ChatCompletionResponseMessage>()
  const initForm = {
    userMessage: ""
  }
  const form = useForm(initForm)
  const input_ref = useRef<TextInput | null>(null);
  const chat = trpc.chat.createChat.mutate


  // end of hooks


  const handleSubmit = async () => {
    form.setSubmitting(true)
    //console.log(form.formData.userMessage)
    //chat.mutate({message: form.formData.userMessage});
    chat({message: form.formData.userMessage, user: userId}).then(res => {
      //console.log("chat finished", res)
      setChatResponse(res)
      form.setSubmitting(false)
      input_ref.current?.focus()
    });
  }

// if (isLoading) return <span>Loading ...</span>;
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require('../assets/images/playing.jpg')}
        resizeMode="cover" style={styles.image}
      >
        <ScrollView
          keyboardShouldPersistTaps={'handled'}
          style={{
            //alignItems: "center",

            backgroundColor: Colors.dark.backgroundAlpha,
            width: "100%",

          }}>
          <View style={{
            ...styles.container,
          }}>

            <Text style={styles.title}>Ask your D&D friend</Text>

            <View style={{
              // ...styles.bordered,
              //flex:1,
              width: "100%",
              alignItems: "center",
              //height: '100%'
            }}>
              <View style={{
                //flex:1
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                //marginTop: 12,

              }}>
                <View style={{
                  //flex:1,
                  //marginTop: 12,
                  width: "82%"
                }}>
                  <TextInput
                    ref={input_ref}
                    style={styles.input}
                    //placeholder=""
                    autoFocus={true}
                    value={form.formData.userMessage}
                    onChangeText={form.handleChange("userMessage")}
                    onSubmitEditing={handleSubmit}
                  />
                </View>
                <TouchableHighlight underlayColor="white"
                                    onPress={e => {
                                      form.setFormData(initForm)
                                      input_ref.current?.focus()
                                    }}
                >
                  <View style={{
                    //...styles.button,
                    flexDirection: "row",
                    padding: "1%"
                  }}>
                    <FontAwesomeIcon color={Colors.dark.text} icon={faCircleXmark}/>
                  </View>
                </TouchableHighlight>
              </View>
              <UiButton
                disabled={form.submitting}
                onPress={handleSubmit}
              >
                <Text style={styles.buttonText}>{form.submitting ? "Working on it.." : "Look into it!"}</Text>
              </UiButton>

              {chatResponse &&
                  <View style={{
                    width: '100%',
                    marginHorizontal: 16,
                    padding: 4,
                    margin: 12,
                    marginBottom: 48,
                  }}>
                    {/*  <Text style={styles.title}>
                    {chatResponse.role}
                  </Text>*/}
                      <Text style={styles.card}>
                        {chatResponse.content}
                      </Text>
                  </View>}

            </View>

          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
}

