import {GestureResponderEvent, TouchableHighlight} from "react-native";
import {Text, View} from "./Themed";
import {styles} from "../constants/Styles";
import React from "react";
import Colors from "../constants/Colors";

interface UiButtonI {
  disabled: boolean
  onPress?: ((event: GestureResponderEvent) => void) | undefined
  children: React.ReactNode
}

const UiButton = (
  {
    disabled,
    onPress,
    children
  }: UiButtonI) => {


  return (
    <TouchableHighlight
      underlayColor="white"
      disabled={disabled}
      onPress={onPress}
    >
      <View style={{
        ...styles.buttonPrimary,
        backgroundColor: disabled ? 'rgba(1,2,3,0.6)' : Colors.dark.tint,
      }}>
        {children}
      </View>
    </TouchableHighlight>
  )
}

export default UiButton