import {Button, ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';


import {Text, View} from '../components/Themed';
import React, {useRef, useState} from "react";
import {useForm} from "../hooks/useForm";
import {styles} from "../constants/Styles";
import Colors from "../constants/Colors";
import {apiURL, trpc} from "../trpc";
import {ChatCompletionResponseMessage} from "openai";
import {faCircleXmark, faChevronDown, faChevronRight} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon,} from '@fortawesome/react-native-fontawesome';
import UiButton from '../components/UiButton';
import {randomId} from "../utils/rando";

export default function NamesScreen() {
  const [userId, setUserId] = useState(randomId())
  const [namesResponse, setNamesResponse] = useState<ChatCompletionResponseMessage>()
  const [showPresets, setShowPresets] = useState(false)
  const initForm = {
    userMessage: ""
  }
  const form = useForm(initForm)
  const input_ref = useRef<TextInput | null>(null);
  const createNames = trpc.names.createNames.mutate
  /*
  const chat = trpc.chat.createChat.useMutation({
    onSuccess: (res) => {
      console.log("onSuccess", res)
      form.setSubmitting(false)
      setChatResponse(res)
    }
  });*/

  // end of hooks

  const handleAddWord = (word: string) => {

    form.setFormData({
      ...form.formData,
      userMessage: `${form.formData.userMessage} ${word}`
    })
  }
  const handleSubmit = async () => {
    form.setSubmitting(true)
    console.log("submit name", form.formData.userMessage)
    //chat.mutate({message: form.formData.userMessage});
    try {
      console.log(apiURL)
      createNames({message: form.formData.userMessage, user:userId}).then(res => {
        console.log("create names finished", res)
        setNamesResponse(res)
        form.setSubmitting(false)
        input_ref.current?.focus()
      });
    } catch (err) {
      console.log(err)
    }
  }

  const presets = [
    "female",
    "male",
    "dwarf",
    "elf",
    "orc",
    "human",
    "hobit",
    "fairy",
    "fey",
    "magical",
    "tiny",
    "giant",
    "skinny",
    "strong",
    "sneaky",
    "crazy",
    "outlandish",
    "towns",
    "realms",
    "gruesome",
    "grim",
    "tolkein",
    "castles",
    "sieges",
    "swords",
    "axes",
    "rivers",
    "valiant",
    "rude",
    "paladin",
    "ranger",
    "cleric",
    "wizard",
    "dragon",
    "dancing",
    "bright",
    "dark",
    "healer",
  ]

// if (isLoading) return <span>Loading ...</span>;
  return (


    <View style={styles.container}>
      <ImageBackground
        source={require('../assets/images/playing.jpg')}
        resizeMode="cover" style={styles.image}
      >
        <ScrollView
          keyboardShouldPersistTaps={'handled'}
          style={{
            //alignItems: "center",

            backgroundColor: Colors.dark.backgroundAlpha,
            width: "100%",

          }}>
          <View style={{
            ...styles.container,
            //backgroundColor: Colors.dark.backgroundAlpha,

          }}>
            <Text style={styles.title}>Let's make up some names</Text>

            <View style={{
              // ...styles.bordered,
              //flex:1,
              width: "100%",
              alignItems: "center",
              //height: '100%'
            }}>
              <View style={{
                //flex:1
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                //marginTop: 12,

              }}>
                <View
                  style={{
                    width: "82%",
                  }}
                >
                  <TextInput
                    ref={input_ref}
                    autoFocus={true}
                    style={styles.input}
                    //placeholder=""
                    onSubmitEditing={handleSubmit}
                    value={form.formData.userMessage}
                    onChangeText={form.handleChange("userMessage")}
                  />
                </View>
                <TouchableHighlight underlayColor="white"
                                    onPress={e => {
                                      form.setFormData(initForm)
                                    }}
                >
                  <View style={{
                    //...styles.button,
                    flexDirection: "row",
                    padding: "4%"
                  }}>
                    <FontAwesomeIcon color={Colors.dark.text} icon={faCircleXmark}/>
                  </View>
                </TouchableHighlight>
              </View>

              <TouchableHighlight underlayColor="white"
                                  onPress={() => {
                                    setShowPresets(!showPresets)
                                  }}>
                <View style={{
                  ...styles.button,
                  flexDirection: "row",
                  padding: 12
                }}>
                  <Text style={{
                    marginRight: 6,
                  }}>Examples</Text>
                  <FontAwesomeIcon color={Colors.dark.text} icon={showPresets ? faChevronDown : faChevronRight}/>
                </View>
              </TouchableHighlight>
              {showPresets && (
                <View style={{
                  marginHorizontal: 16,
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 12,
                }}>
                  {presets.map((p, i) => (
                    <TouchableHighlight key={"preset" + i}
                                        underlayColor="white"
                                        onPress={() => handleAddWord(p)}
                    >
                      <View style={styles.button}>
                        <Text>{p}</Text>
                      </View>
                    </TouchableHighlight>
                  ))}
                </View>
              )}
              <UiButton
                disabled={form.submitting}
                onPress={handleSubmit}
              >
                <Text style={styles.buttonText}>{form.submitting ? "Working on it.." : "Generate New Names"}</Text>
              </UiButton>

              {namesResponse &&
                  <View style={{
                    padding: 4,
                    margin: 12,
                    marginBottom: "20%",
                  }}>
                    {/*  <Text style={styles.title}>
                    {chatResponse.role}
                  </Text>*/}
                      <Text style={styles.card}>
                        {namesResponse.content}
                      </Text>
                  </View>}

            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>

  );
}

