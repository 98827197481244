import {Theme} from "@react-navigation/native";
import Colors from "./Colors";


export const DARK_THEME: Theme = {
  dark: true,
  colors: {
    primary: 'rgb(10, 132, 255)',
    //background: Colors.dark.  backgroundAlpha,//'rgb(1, 1, 1)',
    background: Colors.dark.backgroundAlpha,
    // no border color borderColor: Colors[colorScheme].headera,//'rgb(1, 1, 1)',
    card: 'rgb(18, 18, 18)',
    text: Colors.dark.text,
    border: Colors.dark.text,
    notification: 'rgb(255, 69, 58)',
  },
};

export const LIGHT_THEME: Theme = {
  dark: false,
  colors: {
    primary: 'rgb(0, 122, 255)',
    background: Colors.light.background,// 'rgb(242, 242, 242)',
    card: 'rgb(255, 255, 255)',
    text: Colors.light.text,
    border: 'rgb(33,128,22)',
    notification: 'rgb(255, 59, 48)',
  },
};
