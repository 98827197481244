import {createTRPCProxyClient, httpBatchLink} from '@trpc/client'; // plain
//import { createTRPCReact, httpBatchLink } from '@trpc/react-query'; // react query

import type {AppRouter} from '../../dnd-friend-api/src/index';

const PORT = 6180
export let apiURL: string;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  apiURL = `http://localhost:${PORT}/trpc`
  //apiURL = "https://api.dndfriend.com/trpc";
} else {
  apiURL = "https://api.dndfriend.com/trpc";
}

export let token: string = "";
export const setToken = (t: string) => {
  token = t
}
export const trpc = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: apiURL,
      headers() {
        return {
          "Authorization": `Bearer ${token}`,
        }
      }
    }),

  ],
});


//react-query
/*
export const trpc = createTRPCReact<AppRouter>();

const PORT = 6180
export const trpcClient = trpc.createClient({

  links: [
    httpBatchLink({
      url: `http://localhost:${PORT}/trpc`,
    }),
  ],
});
*/
