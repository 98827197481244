const tintColorLight = 'rgb(162,117,0)';
const tintColorDark = 'rgb(162,117,0)';
const fontColor = 'rgba(252,253,255,0.8)'
const iconColor = 'rgba(252,253,255,1)'

const darkBlue = `rgb(12, 17, 24)`
const darkBlueAlpha = `rgba(12, 17, 24, 0.6)`
const darkBlueAccent = `rgba(140, 157, 172, 0.0)`


export default {
  light: {
    text: fontColor,
    background: '#b4b4b4',
    header:darkBlueAlpha,
    tint: tintColorLight,
    tabIconDefault: iconColor,
    tabIconSelected: iconColor,
  },
  dark: {
    text: fontColor,
    background: darkBlue,
    backgroundAlpha: darkBlueAlpha,
    header:darkBlueAlpha,
    tint: tintColorDark,
    tabIconDefault: iconColor,
    tabIconSelected: iconColor,
  },
};
