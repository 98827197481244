/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {FontAwesome} from '@expo/vector-icons';


import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {NavigationContainer, DefaultTheme, DarkTheme} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import * as React from 'react';
import {ColorSchemeName, ImageBackground, Pressable, Text, View} from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import ProfileScreen from '../screens/ProfileScreen';
import TabTwoScreen from '../screens/TabTwoScreen';
import {RootStackParamList, RootTabParamList, RootTabScreenProps} from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import {DARK_THEME, LIGHT_THEME} from "../constants/Themes";
import ChatScreen from "../screens/ChatScreen";
import {CONSTANTS, styles} from "../constants/Styles";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faBook, faComments, faDice, faHatWizard} from "@fortawesome/free-solid-svg-icons"
import NamesScreen from "../screens/NamesScreen";
import DiceScreen from "../screens/DiceScreen";
import DmScreen from "../screens/DmScreen";

export default function Navigation({colorScheme}: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DARK_THEME : LIGHT_THEME}>

      <RootNavigator/>
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (

    <Stack.Navigator>

      <Stack.Screen name="Root" component={BottomTabNavigator} options={{headerShown: false}}/>
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{title: 'Oops!'}}/>
      <Stack.Group screenOptions={{presentation: 'modal'}}>
        <Stack.Screen name="Modal" component={ModalScreen}/>
      </Stack.Group>
    </Stack.Navigator>

  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  let colorScheme = useColorScheme();
  colorScheme = "dark"

  return (
    <BottomTab.Navigator
      initialRouteName="Dm"
      screenOptions={{
        headerLeft: () => (
          <View>
            <Text style={styles.logo}>D&D Friend</Text>
          </View>
        ),
        tabBarActiveTintColor: Colors[colorScheme].tint,
        tabBarStyle: {
          height: 72,
          opacity: 1,
          paddingHorizontal: 5,
          paddingTop: 0,
          paddingBottom: 16,
          backgroundColor: Colors.dark.backgroundAlpha,
          position: 'absolute',
          borderTopWidth: 0,
        },
        headerStyle: {
          height: CONSTANTS.navBarHeight,
          borderBottomWidth: 0,
          //borderColor: Colors[colorScheme].header,
         //backgroundColor: Colors[colorScheme].header,
        },
      }}
    >
      <BottomTab.Screen
        name="Profile"
        component={ProfileScreen}
        options={({navigation}: RootTabScreenProps<'Profile'>) => ({
          title: 'Profile',
          tabBarIcon: ({color}) => <TabBarIcon name="user" color={color}/>,
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate('Modal')}
              style={({pressed}) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome
                name="info-circle"
                size={25}
                color={Colors[colorScheme].text}
                style={{marginRight: 15}}
              />
            </Pressable>
          ),

        })}
      />

      {/*  <BottomTab.Screen
        name="TabTwo"
        component={TabTwoScreen}
        options={{
          title: 'Tab Two',
          tabBarIcon: ({color}) => <TabBarIcon name="code" color={color}/>,

        }}
      />*/}
      <BottomTab.Screen
        name="Chat"
        component={ChatScreen}
        options={{
          title: 'Chat',
          //todo style this
//          tabBarIcon: ({color}) => <TabBarIcon name="comments" color={color}/>,
          tabBarIcon: ({color}) => <TabBarIcon icon={faComments} color={color}/>,

        }}
      />
      <BottomTab.Screen
        name="Names"
        component={NamesScreen}
        options={{
          title: 'Names',
          tabBarIcon: ({color}) => <TabBarIcon icon={faBook} color={color}/>,

        }}
      />
      <BottomTab.Screen
        name="Dice"
        component={DiceScreen}
        options={{
          title: 'Dice',
          tabBarIcon: ({color}) => <TabBarIcon icon={faDice} color={color}/>,

        }}
      />
      <BottomTab.Screen
        name="Dm"
        component={DmScreen}
        options={{
          title: 'Dm',
          tabBarIcon: ({color}) => <TabBarIcon icon={faHatWizard} color={color}/>,

        }}
      />
    </BottomTab.Navigator>

  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name?: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
  icon?: IconProp
}) {
  if (props.name) {
    return <FontAwesome size={30} style={{marginBottom: -3}} {...props} />;
  } else if (props.icon) {
    return <FontAwesomeIcon
      size={30}
      color={props.color}
      style={{
        marginBottom: "-1%",
      }} icon={props.icon}/>;
  } else {
    return null
  }
}